/* eslint-disable no-undef */
import Cookies from "js-cookie";

const getScript = (source, callback) => {
  const el = document.createElement("script");
  el.onload = callback;
  el.src = source;
  document.body.appendChild(el);
};

const grecaptchaReady = new Promise((resolve, reject) => {
  document.addEventListener("DOMContentLoaded", () => {

    // Get recaptcha cookie, if it doesn't exist don't execute this
    const recaptchaSiteKey = Cookies.get("recaptcha");
    if (recaptchaSiteKey === undefined) return;

    getScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`, function() {
      grecaptcha.ready(function() { resolve(grecaptcha) }) })
  })
})
window.grecaptchaReady = grecaptchaReady

function recaptchaExecution(evt) {
  const form = evt.target;

  // If not post don't get recaptcha
  if (form.method.toUpperCase() !== "POST") return
  // Check if we already added a recaptcha input
  let recaptchaInput = form.querySelector('input[name="recaptcha"]')
  if (recaptchaInput && recaptchaInput.value) return

  // Get recaptcha token and add to form before submitting
  evt.preventDefault();
  const recaptchaSiteKey = Cookies.get("recaptcha");
  grecaptcha.execute(recaptchaSiteKey, {action: 'submit'}).then(function(token) {
    const el = document.createElement("input");
    el.name = "recaptcha";
    el.type = "hidden";
    el.value = token;
    form.appendChild(el);
    form.submit();
    // Reset the form in case they reload it from the back/forward cache
    el.remove()
  });
}
window.recaptchaExecution = recaptchaExecution

const grecaptchaAppliedToForms = grecaptchaReady.then(grecaptcha => {
  document.body.addEventListener('submit', recaptchaExecution)
})
window.grecaptchaAppliedToForms = grecaptchaAppliedToForms

grecaptchaReady.then(_ => {
  // Hide recaptcha badge
  document.querySelector(".grecaptcha-badge").style.visibility = "collapse";

  // Fix for reserved namespace on buttons
  const submitButtons = document.querySelectorAll("input[name='submit'], button[name='submit']");
  submitButtons.forEach(button => {
    button.name = "submit_form";
  });
})
